var _ = function _(t) {
    return t;
  },
  _t,
  _t2,
  _t3;
import { html } from 'lit';
import Translations from '../Translations.js';
var OrderByBlock = function OrderByBlock(order, language, onClick) {
  var arrow = html(_t || (_t = _`<span class="comments-arrow comments-arrow--up"><svg viewBox="0 0 20 20" width="20" height="20" focusable="false" role="img" aria-hidden="true"><path d="M15.302 6.24l-4.462 4.605-.84.875-.84-.875L4.696 6.24c-.378-.167-.578.724-.576 1.293v.002c.002.189.025.342.072.408l5.462 5.64c.19.264.503.264.692 0 1.478-1.526 2.587-2.67 3.326-3.435l2.135-2.205c.048-.066.072-.203.073-.373v-.01c.002-.516-.2-1.32-.578-1.32" fill="currentColor"></path></svg></span>`));
  if (order === "desc") arrow = html(_t2 || (_t2 = _`<span class="comments-arrow comments-arrow--down"><svg viewBox="0 0 20 20" width="20" height="20" focusable="false" role="img" aria-hidden="true"><path d="M15.302 6.24l-4.462 4.605-.84.875-.84-.875L4.696 6.24c-.378-.167-.578.724-.576 1.293v.002c.002.189.025.342.072.408l5.462 5.64c.19.264.503.264.692 0 1.478-1.526 2.587-2.67 3.326-3.435l2.135-2.205c.048-.066.072-.203.073-.373v-.01c.002-.516-.2-1.32-.578-1.32" fill="currentColor"></path></svg></span>`));
  return html(_t3 || (_t3 = _`
        <div class="button__render-order"
            @click=${0}
        >
            ${0} ${0}
        </div>
    `), onClick, Translations.order_by_date[language], arrow);
};
export default OrderByBlock;