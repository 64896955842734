var _ = function _(t) {
    return t;
  },
  _t,
  _t2,
  _t3,
  _t4,
  _t5,
  _t6,
  _t7;
import { html } from 'lit';
import Translations from '../Translations.js';
import PreviousIcon from "../../../common/images/ic_previous.svg";
import PrevIcon from "../../../common/images/ic_prev.svg";
import LastIcon from "../../../common/images/ic_last.svg";
import NextIcon from "../../../common/images/ic_next.svg";
var Pager = function Pager(currentPage, commentCount, entriesPerPage, language, displayShowAllButton, changePageFunc, changeEntriesPerPageFunc, scrollFunc) {
  var pageCount = Math.ceil(commentCount / entriesPerPage);
  var pagesArray = [html(_t || (_t = _`
        <div
            class="pager-page__previous" style="background: url('${0}') no-repeat;"
            @click=${0}
        >
        </div>
    `), PreviousIcon, function () {
    changePageFunc(1);
    scrollFunc();
  }), html(_t2 || (_t2 = _`
        <div
            class="pager-page__prev" style="background: url('${0}') no-repeat;"
            @click=${0}
        >
        </div>
    `), PrevIcon, function () {
    changePageFunc(currentPage - (currentPage > 1 ? 1 : 0));
    scrollFunc();
  })];
  var elementsBeforeActive = 3;
  var _loop = function _loop(i) {
    var upperLimit = elementsBeforeActive;
    var lowerLimit = elementsBeforeActive;
    if (currentPage <= elementsBeforeActive) {
      upperLimit += 1 + elementsBeforeActive - currentPage;
    }
    if (currentPage >= pageCount - elementsBeforeActive) {
      lowerLimit += elementsBeforeActive + currentPage - pageCount;
    }
    if (i < currentPage - lowerLimit || i > currentPage + upperLimit) return 1; // continue
    var activeClass = "";
    if (currentPage === i) activeClass = "pager-page--active";
    pagesArray.push(html(_t7 || (_t7 = _`
            <div
                class="pager-page__nbr ${0}"
                @click=${0}
            >
                ${0}
            </div>
        `), activeClass, function () {
      changePageFunc(i);
      scrollFunc();
    }, i));
  };
  for (var i = 1; i < pageCount + 1; i++) {
    if (_loop(i)) continue;
  }
  pagesArray.push(html(_t3 || (_t3 = _`
        <div
            class="pager-page__last" style="background: url('${0}') no-repeat;"
            @click=${0}
        >
        </div>
    `), LastIcon, function () {
    {
      changePageFunc(currentPage + (currentPage < pageCount ? 1 : 0));
      scrollFunc();
    }
  }));
  pagesArray.push(html(_t4 || (_t4 = _`
        <div
            class="pager-page__next" style="background: url('${0}') no-repeat;"
            @click=${0}
        >
        </div>
    `), NextIcon, function () {
    changePageFunc(pageCount);
    scrollFunc();
  }));
  if (commentCount <= entriesPerPage) return;
  var showAllButton = null;
  if (displayShowAllButton) showAllButton = html(_t5 || (_t5 = _`<div class="comments__show-all button-default-co" @click=${0}><span>${0}</span></div>`), function () {
    changePageFunc(1);
    changeEntriesPerPageFunc(commentCount);
  }, Translations.show_all_answers[language]);
  return html(_t6 || (_t6 = _`<div class="comments-container__pager">
                    <div class="comments__nb">${0}</div>
                    ${0}
                </div>`), pagesArray, showAllButton);
};
export default Pager;