var _ = function _(t) {
    return t;
  },
  _t,
  _t2,
  _t3;
import { html } from 'lit';
import Translations from '../Translations.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
var SetNicknameBlock = function SetNicknameBlock(language, loading, submitChange) {
  var submitButton = html(_t || (_t = _`<div
                                class="comments-nickname__button"
                                @click=${0}
                            >
                                ${0}
                            </div>`), function (e) {
    submitChange();
  }, Translations.post_nickname[language]);
  if (loading > 0) submitButton = html(_t2 || (_t2 = _`<div class="comments-nickname__button"><div class="spinner"></div></div>`));
  return html(_t3 || (_t3 = _`
        <div id="main-comment-block" class="comments-nickname__container">
            <p class="comments-nickname" id="nickname-error-text">${0}</p>
            <input class="comments-nickname__text" type="text" id="new-nickname-input" />
            ${0}
        </div>
        `), unsafeHTML(Translations.set_nickname[language]), submitButton);
};
export default SetNicknameBlock;