var _ = function _(t) {
    return t;
  },
  _t,
  _t2;
import { html } from 'lit';
var Thumb = function Thumb(up, set) {
  if (up) {
    var _activeClass = '';
    if (set) _activeClass = 'thumbs-like';
    return html(_t || (_t = _`
        <div class="thumbs ${0}">
            <svg id="Calque_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.02 17.6"><path fill="currentColor" d="m0,5.6h5.6v12H0V5.6Zm19.1,1.9c-.6-.7-1.6-1.2-2.6-1.2h-3.2v-2.8c0-1.9-1.6-3.5-3.5-3.5h-.6v4.2c0,1.2-1,2.2-2.2,2.2h0v9.4l2.7,1.8h9.2l1.1-7.3c.1-1-.2-2-.9-2.8Z"/></svg>
        </div>`), _activeClass);
  }
  var activeClass = '';
  if (set) activeClass = 'thumbs-dislike';
  return html(_t2 || (_t2 = _`
    <div class="thumbs thumbs--margin ${0}">
        <svg id="Calque_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.02 17.7"><path fill="currentColor" d="m0,0h5.6v12H0V0Zm20,7.4L18.9.1h-9.2l-2.7,1.8v9.4h0c1.2,0,2.2,1,2.2,2.2v4.2h.6c1.9,0,3.5-1.6,3.5-3.5v-2.9h3.2c1,0,1.9-.4,2.6-1.2.7-.8,1-1.8.9-2.7Z"/></svg>
    </div>`), activeClass);
};
export default Thumb;